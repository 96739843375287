import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { franc } from "franc";
import { shortLanguage, currentLanguage } from "./settings";
import { formatPrice } from "./utils";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

const CustomInput = ({
  validate,
  defaultValue,
  type,
  className,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  otherLang,
  style,
  checked,
  disabled,
  separator,
  ...props
}) => {
  const { t } = useTranslation();
  const [err, setErr] = useState(false);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleOnChange = event => {
    console.log(validate)
    onChange(event);
  };

  const applyFormatting = event => {
    event.target.value = separator ? formatPrice(parseFloat(event.target.value)) : event.target.value;
  };

  const debouncedApplyFormatting = debounce(applyFormatting, 300);

  const handleOnFocus = event => {
    if (event.target.value.includes(",")) {
      // Remove commas from the value if they exist
      event.target.value = event.target.value.replace(/,/g, "");
    }
  };

  const styles = {
    ...style,
    backgroundSize: 18,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 3px) center",
    backgroundColor: "#fff",
    backgroundImage:
      !validate && value && value.length > 0
        ? "url(/assets/images/check_icon.png)"
        : validate && validate.length > 0
          ? "url(/assets/images/cross_icon.png)"
          : "none",
  };

  return (
    <div className="customInput">
      <input
        maxLength={props.maxLength}
        type={type}
        className={`${className} ${validate && validate.length > 0 && "errorInput"}`}
        placeholder={t(placeholder)}
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleOnChange}
        onFocus={handleOnFocus} // Added onFocus event handler
        onBlur={separator ? debouncedApplyFormatting : onBlur}
        style={styles}
        checked={checked}
        defaultValue={separator ? formatPrice(parseFloat(defaultValue)) : defaultValue}
      />
      {/* <div className='error'>{err && `Value should be in ${currentLanguage}`}</div> */}
    </div>
  );
};

export default CustomInput;
